import styled, { keyframes } from 'styled-components'
import media from 'styled-media-query'
import { Whatsapp } from '@styled-icons/boxicons-logos/Whatsapp'

const shake = keyframes`
    10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
`

export const Container = styled.a`
    position: fixed;
    z-index: 500;
    bottom: 30px;
    left: 10px;
    width: 50px;
    background-color: #4AC959;
    padding: 10px;
    border-radius: 50px;
    box-shadow: 0px 0px 18px -7px rgba(0,0,0,0.58);

    &:hover {
        animation: ${shake} 0.8s cubic-bezier(.36,.07,.19,.97) both;
    }

    ${media.lessThan("medium")`
        width: 35px;
    `}
`

export const WhatsAppLogo = styled(Whatsapp)`
    color: #fff;
    
`