import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import SocialLinks from '../SocialLinks'

import * as S from './styled'

const Footer = () => {

    const { logoImage } = useStaticQuery(
        graphql`
            query {
                logoImage: file (relativePath: { eq: "logo-darby.png" }) {
                  childImageSharp {
                    fixed(width: 130) {
                      ...GatsbyImageSharpFixed
                    }
                  }
                } 
              }
        `
    )

    return (
        <>
            <S.Footer>
                <S.Logo fixed={logoImage.childImageSharp.fixed} alt="Logo Darby Tisatto" />
                <S.Address>
                    <S.InfosInnerContainer>
                        <h4>R. Vinte de Setembro, 2353 <br /> Centro <br /> Caxias do Sul - RS <br /> 95020-450</h4>
                    </S.InfosInnerContainer>
                    <S.Contatos title="Telefone" href="tel:+555430660486" target="_blank" rel="noopener noreferrer" >
                        <S.IconeTelefone />
                        <h3>54.3066.0486</h3>
                    </S.Contatos>
                    <S.Contatos title="Telefone" href="tel:+5554997108252" target="_blank" rel="noopener noreferrer" >
                        <S.IconeTelefone />
                        <h3>54.99710.8252</h3>
                    </S.Contatos>
                </S.Address>
                <S.Nav >
                    <ul>
                        <li><S.FooterLink to="/"  >Início</S.FooterLink></li>
                        <li><S.FooterLink to="/sobre" >Apresentação</S.FooterLink></li>
                        <li><S.FooterLink to="/doencas" >Doenças da Coluna</S.FooterLink></li>
                        <li><S.FooterLink to="/tratamentos" >Tratamentos</S.FooterLink></li>
                        <li><S.FooterLink to="/blog" >Notícias</S.FooterLink></li>
                        <li><S.FooterLink to="/contato">Contato</S.FooterLink></li>
                    </ul>
                </S.Nav>
                <SocialLinks />
            </S.Footer>
        </>
    )
}

export default Footer