import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby'
import { Link } from 'gatsby'

import * as S from './styled'

const Navbar = ({ textColor }) => {

    const { logoImage } = useStaticQuery(
        graphql`
            query {
                logoImage: file (relativePath: { eq: "logo-darby.png" }) {
                  childImageSharp {
                    fixed(width: 170) {
                      ...GatsbyImageSharpFixed
                    }
                  }
                } 
              }
        `
    )

    const [showNav, setShowNav] = useState(false)

    return (
        <S.Header>
            <Link to="/"><S.NavLogo fixed={logoImage.childImageSharp.fixed} alt="Logo Darby Tisatto" /></Link>
            <S.HamburguerMenu textColor={ textColor ? true : false } onClick={ () => setShowNav(!showNav) } nav={showNav} >
              <div />
              <div />
              <div />
            </S.HamburguerMenu>
            <S.Nav nav={showNav} >
                <ul>
                    <li><S.NavbarLink activeClassName="active" to="/" textColor={ textColor ? true : false } >Início</S.NavbarLink></li>
                    <li><S.NavbarLink activeClassName="active" to="/sobre" textColor={ textColor ? true : false } >Apresentação</S.NavbarLink></li>
                    <li><S.NavbarLink activeClassName="active" to="/doencas" textColor={ textColor ? true : false }>Doenças da Coluna</S.NavbarLink></li>
                    <li><S.NavbarLink activeClassName="active" to="/tratamentos" textColor={ textColor ? true : false }>Tratamentos</S.NavbarLink></li>
                    <li><S.NavbarLink activeClassName="active" to="/blog" textColor={ textColor ? true : false }>Notícias</S.NavbarLink></li>
                    <li><S.NavbarLink activeClassName="active" to="/contato" textColor={ textColor ? true : false }>Contato</S.NavbarLink></li>
                </ul>
            </S.Nav>
        </S.Header>
    )
}

export default Navbar