import React from "react"

import * as S from './styled'

const WhatsAppButton = () => {
    return (
        <>
            <S.Container href="https://api.whatsapp.com/send?phone=55549997108252" title="Whatsapp" target="_blank" rel="noopener noreferrer">
                <S.WhatsAppLogo />
            </S.Container>
        </>
    )
}

export default WhatsAppButton