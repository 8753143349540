import styled from 'styled-components'

export const SocialLinksWrapper = styled.nav`
`

export const SocialLinksList = styled.ul`
    align-items: center;
    display: flex;
    justify-content: space-around;
    list-style: none;
`

export const SocialLinksItems = styled.li`
    margin-right: 5px;
`

export const SocialLinksLink = styled.a`
    color: #26938F;
    text-decoration: none;
    transition: color 0.5s ease;

    &:hover {
        color: #214191;
    }
`

export const IconWrapper = styled.li`
    fill: #bbb;
    width: 30px;
    height: 30px;
`
