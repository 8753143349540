import styled from 'styled-components'
import media from 'styled-media-query'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

export const Header = styled.header`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 25px 160px;
    position: relative;
    z-index: 999;

    ${media.lessThan("huge")`
        padding: 25px 60px;
    `}
`

export const NavLogo = styled(Img)`
    mix-blend-mode: multiply;
`


export const HamburguerMenu = styled.button`
    
    position: absolute;
    right: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 1.5rem;
    height: 1.5rem;
    background: transparent;
    border: none;
    cursor: pointer;
    z-index: 50;

    div {
        width: 1.42rem;
        height: 0.2rem;
        background: ${ ({ textColor }) => textColor ? "#1E2738" : "#FFF" };
        border-radius: 5px;
        transform-origin: 1px;
        position: relative;

        transition: all 0.3s ease;

        :first-child {
            transform: ${ ({ nav }) => nav ? "rotate(45deg)" : "rotate(0deg)" };
        }

        :nth-child(2) {
            opacity: ${ ({ nav }) => nav ? "0" : "1" };
        }

        :nth-child(3) {
            transform: ${ ({ nav }) => nav ? "rotate(-45deg)" : "rotate(0deg)" };
        }
    }

    &:focus {
        outline: none;
    }

    ${media.greaterThan('1350px')`
        display: none;
    `}

    ${media.lessThan("medium")`
        div {
            background: ${ ({ nav }) => nav ? "#FFF" : "#464D5B" }
        }
    `}

`

export const Nav = styled.nav`

    ul {
        display: flex;
    }

    li {
       margin-left: 10px; 
    }

    ${media.lessThan('1350px')`

        position: fixed;
        top: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        margin-top: auto;
        right: 0;
        top: 0;
        text-align: left;
        flex-direction: column;
        background: #464D5B;
        height: 100vh;    
        padding-right: 70px;

        opacity: ${ ({ nav }) => nav ? "1" : "0" };
        transform: ${ ({ nav }) => nav ? "translateX(0)" : "translateX(100%)" };
        transition: all 0.5s ease-in-out;

        ul {
            padding-top: 90px;
            flex-direction: column; 
        }
        
    `}

`

export const NavbarLink = styled(Link)`
    display: inline-block;
    position: relative;
    padding: 10px;

    text-transform: uppercase;
    color: ${ ({ textColor }) => textColor ? "#1E2738" : "#FFF" };
    font-weight: 700;
    font-size: 0.9rem;
    letter-spacing: 1.2px;
    font-family: 'Montserrat', sans-serif;


    &::before {
        content: "";
        width: 0;
        background: #0C8E86;
        height: 5px;
        position: absolute;
        top: -52px;
        left: 0px;
        transition: all 0.4s ease
        }

    &:hover {
        &::before {
            width: 100%;
        }
    }

    &.active {
        &::before {
            width: 100%;
        }
  }

  ${media.lessThan('1350px')`

    padding: 20px;
    color: #fff;

    &::before {
        height: 3px;
        position: absolute;
        top: 45px;
        left: 0px;
        transition: all 0.4s ease
        }
    `}  

`