import React from 'react'

import * as S from './styled'

import Icons from './Icons'
import links from './content'

const SocialLinks = () => {

    return (
        <S.SocialLinksWrapper>
            <S.SocialLinksList>
                {
                    links.map((link, i) => {
                        const Icon = Icons[link.label]
                        return (
                            <S.SocialLinksItems key={i} >
                                <S.SocialLinksLink href={link.url} title={link.label} target="_blank" rel="noopener noreferrer" > 
                                    <S.IconWrapper>
                                        <Icon />
                                    </S.IconWrapper>
                                </S.SocialLinksLink>
                            </S.SocialLinksItems>
                        )
                    })
                }
            </S.SocialLinksList>
        </S.SocialLinksWrapper>
    )
}

export default SocialLinks