const links = [
    {
        label: "Facebook",
        url: "https://www.facebook.com/drdarbytisatto/"
    },
    {
        label: "Instagram",
        url: "https://www.instagram.com/darbytisatto/"
    }
]

export default links