import styled from 'styled-components'
import media from 'styled-media-query'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

import { Telephone } from '@styled-icons/foundation/Telephone'

export const Footer = styled.footer`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 50px 160px;
    position: relative;
    bottom: 0;
    z-index: 499;
    background-color: #E6E7E8;

    ${media.lessThan("huge")`
        padding: 25px 60px;
    `}

    ${media.lessThan("large")`
        flex-direction: column;
    `}
`

export const Logo = styled(Img)`
    mix-blend-mode: multiply;
`

export const Nav = styled.nav`
    
    ul {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 10px;
    }

    li {
        margin-left: 10px; 
    }

    ${media.lessThan("large")`
        ul {
            display: flex;
            text-align: center;
            flex-direction: column;
            padding-bottom: 20px; 
        }
        
    `}

`

export const FooterLink = styled(Link)`
    display: inline-block;
    position: relative;
    padding: 10px;

    text-transform: uppercase;
    color: #214191;
    font-weight: 700;
    font-size: 0.9rem;
    letter-spacing: 1.2px;
    font-family: 'Montserrat', sans-serif;


  ${media.lessThan("large")`

    padding: 20px;

    &::before {
        height: 3px;
        position: absolute;
        top: 45px;
        left: 0px;
        transition: all 0.4s ease
        }
    `}  
`

export const Address = styled.div`
    margin: 40px 0;
`

export const InfosInnerContainer = styled.div`

    h4 {
        
        font-family: 'Montserrat', sans-serif;
        font-weight: 700;
        font-size: 0.9rem;
        color: #58595B;
        line-height: 20px;

        margin-bottom: 20px;

    }
`

export const Contatos = styled.a`
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 0.9rem;
    color: #2D60AB;

    margin-bottom: 10px;

    display: flex;
    flex-direction: row;
    align-items: center;

    cursor: pointer;
    
    h3 {
        margin-left: 5px;

    }
`

export const IconeTelefone = styled(Telephone)`
    width: 30px;
    color: #0C8E86;
`